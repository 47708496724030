<template>
  <!--
    The view for the SoftwareComponentCommands-component.
    Used to display the messages within a template.
  -->
  <Portlet
    :sub-title="subTitle"
    :title="`${ $t('softwareComponent.softwareComponentCommands') }:`"
    icon="clone"
  >
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template
        slot="buttons"
      >
        <button
          class="btn btn-primary float-right mt-2"
          @click="showAddCommands"
        >
          <font-awesome-icon
            class="mr-2"
            icon="plus"
          />
          <span>{{ $t('add') }}</span>
        </button>
        <div class="clearfix" />
      </template>
      <SoftwareComponentCommandList
        ref="componentCommandsList"
        :type-id="componentTypeId" 
        @reloadAuditLogs="reloadAuditLogs"
      />
      <DatabaseAuditLogList
        ref="databaseAuditLogList"
        :is-software-component-command="true"
        :software-component-command-parent="componentTypeId"
        @reload="reloadComponentCommandsList"
      /> 
    </template>
  </Portlet>
</template>

<script>
export default {
  name: "SoftwareComponentCommandsOverview",
  components: {
    SoftwareComponentCommandList: () => import('@/components/SoftwareComponent/SoftwareComponentCommandList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  props: {
    componentTypeId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      componentType: null
    }
  },
  metaInfo () {
    return {
      title: this.$t('softwareComponent.softwareComponentCommands')
    }
  },
  computed: {
    subTitle: function () {
      let result = this.$t('softwareComponent.softwareComponentCommands');
      if (!this.componentType) {
        return result;
      }
      if (!this.componentType.name) {
        return result;
      }

      return `${ this.componentType.name }`;
    }
  },
  created () {
    this.getComponentType();
  },
  methods: {
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    reloadComponentCommandsList () {
      this.$refs.componentCommandsList.reloadSoftwareComponentCommands();
    },
    showAddCommands () {
      this.$refs.componentCommandsList.showAddComponentCommand();
    },
    getComponentType () {
      this.loading = true;
      this.axios.get(`/SoftwareComponent/GetSoftwareComponentType?id=${ this.componentTypeId }`)
        .then((response) => {
          this.componentType = response.data;
        })
        .finally(() => { this.loading = false });
    },
  },
}
</script>
